<template>
  <button ref="button" class="button-catalog" @click.prevent="showCatalog">
    <img
      class="button-catalog__image"
      src="~~/assets/icons/catalog.svg"
      :alt="_T('@Goods catalog')"
    />
    <span class="button-catalog__title">{{ _T("@Goods catalog") }}</span>
  </button>
</template>

<script setup>
import { useHeaderStore } from "~~/store/headerStore";

const header = useHeaderStore();
const modalCatalog = header.modalCatalog;
const activeCatalog = header.activeCatalog;

const button = ref(null);

const emits = defineEmits(["buttonCatalog"]);

function showCatalog() {
  if (modalCatalog.active) {
    activeCatalog(false);
    document.body.style.overflow = "auto";
  } else {
    activeCatalog(true);
    document.body.style.overflow = "hidden";
  }
}

onMounted(() => {
  emits("buttonCatalog", button.value);
});
</script>

<style lang="scss" scoped>
.button-catalog {
  width: max-content;

  @include flex-container(row, flex-start, center);

  background-color: var(--color-primary-base);
  border-radius: 6px;

  padding: 8px;
  gap: 8px;

  &__title {
    @include font(16, 18, 700);
    color: white;
    white-space: nowrap;
  }
}
</style>
