<template>
  <div v-if="offers.length" class="offer" :class="{ active: activeBanner }">
    <button class="offer__close-btn" @click="closeModal">
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1.35355"
          y1="0.646447"
          x2="12.3536"
          y2="11.6464"
          stroke="white"
        />
        <line
          x1="0.646447"
          y1="11.6464"
          x2="11.6464"
          y2="0.646447"
          stroke="white"
        />
      </svg>
    </button>
    <div class="offer__wrp">
      <template v-for="item in offers" :key="item.sys.id">
        <div class="offer__content">
          <div class="offer__text">
            <h3 class="offer__title">
              {{ item.fields.title?.[getUserLanguage.name] }}
            </h3>
            <p class="offer__subtitle">
              {{ item.fields.description?.[getUserLanguage.name] }}
            </p>
          </div>

          <div
            v-if="item.fields.icon1 || item.fields.icon2"
            class="offer__offers"
          >
            <div class="offer__offer">
              <img
                :src="item.fields.icon1?.ru.fields.file.ru.url"
                :alt="
                  item.fields.icon1?.ru.fields.title?.[getUserLanguage.name]
                "
              />
            </div>
            <div class="offer__offer">
              <img
                :src="item.fields.icon2?.ru.fields.file.ru.url"
                :alt="
                  item.fields.icon2?.ru.fields.title?.[getUserLanguage.name]
                "
              />
            </div>
          </div>
          <button
            v-if="item.fields.subscriptionForm.ru"
            class="offer__btn-details"
            @click.prevent="modalStore.toggleModal(installerSubscriptionModal)"
          >
            {{ item.fields.nameBtn?.[getUserLanguage.name] }}
          </button>
          <NuxtLink
            v-else
            class="offer__btn-details"
            :to="item.fields.link?.[getUserLanguage.name]"
            @click="closeModal"
          >
            {{ item.fields.nameBtn?.[getUserLanguage.name] }}
          </NuxtLink>
        </div>
        <div class="offer__img-wrp">
          <img
            :src="item.fields.img?.ru.fields.file.ru.url"
            :alt="item.fields.title?.[getUserLanguage.name]"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useContentfulStore } from "~~/store/contentfulStore";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import {
  installerSubscriptionModal,
  useModalStore,
} from "~/modules/shared/components/modals/store/modalStore";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const offers = useContentfulStore().getOffers;

const modalStore = useModalStore();

const activeBanner = ref(false);
const bgImage = ref("#C4C4C4");
const image = ref("");

function getBgImage(item) {
  bgImage.value = item.fields.imgBack
    ? `url('${item.fields.imgBack.ru.fields.file.ru.url}')`
    : "#C4C4C4";
}

function getImage(item) {
  image.value = item.fields.img ? item.fields.img.ru.fields.file.ru.url : "";
}

function setCookie(name, value) {
  document.cookie = name && value ? `${name}=${value}` : false;
}

function getCookie(name) {
  const exp = new RegExp(name + "=([^;]){1,}");
  let search = exp.exec(document.cookie);

  if (search) {
    search = search[0].split("=");
  } else {
    return false;
  }
  return search[1] ? search[1] : false;
}

function closeModal() {
  activeBanner.value = false;
  setCookie("isOffer", true);
}

function checkCookies() {
  if (getCookie("isOffer") === "true") {
    activeBanner.value = false;
  } else {
    activeBanner.value = true;
  }
}

onMounted(() => {
  if (offers.length) {
    getBgImage(offers[0]);
  }
  checkCookies();
});
</script>

<style lang="scss" scoped>
.offer {
  max-width: 400px;
  width: 100%;

  position: fixed;
  right: -100%;
  bottom: 8px;
  z-index: 500;

  background-repeat: no-repeat;
  background-size: cover;
  background-image: v-bind(bgImage);
  background-color: v-bind(bgImage);

  border-radius: 5px;

  padding: 16px;

  transition: right 0.5s ease-in-out;

  &.active {
    right: 8px;

    @include bigMobile {
      right: 0;
    }
  }

  @include bigMobile {
    max-width: 100%;
    min-height: auto;

    bottom: 0;

    border-radius: 0;
  }

  &__close-btn {
    width: 30px;
    height: 30px;

    position: absolute;
    top: -15px;
    right: 16px;

    @include flex-container(column, center, center);

    border: 1px solid white;
    border-radius: 50%;

    background-color: rgba(0, 0, 0, 0.3);

    &:hover,
    &:active {
      background-color: #fb3f4c;
    }
  }

  &__wrp {
    @include bigMobile {
      @include flex-container(row, space-between, center);
    }
  }

  &__content {
    max-width: 212px;
    width: 100%;

    @include flex-container(column, space-between);

    gap: 4px;
  }

  &__text {
    @include flex-container(column, space-between);

    gap: 4px;
  }

  &__title {
    @include font(36, 40, 700);
    color: white;
    text-transform: uppercase;
  }

  &__subtitle {
    @include font(16, 18);
    color: white;
  }

  &__offers {
    @include flex-container(row, flex-start, center);

    gap: 16px;

    @include bigMobile {
      height: 100%;

      position: absolute;
      top: 0;
      right: 16px;

      @include flex-container(column, center, center);
    }
  }

  &__btn-details {
    width: max-content;

    @include font(18, 22);
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    color: white;

    background-color: rgba(0, 0, 0, 0.6);

    border: 1px solid #ffffff;
    border-radius: 5px;

    padding: 8px;

    &:hover,
    &:active {
      background-color: rgba(35, 31, 62, 0.8);
      box-shadow: 0 0 8px #fff;
    }
  }

  &__img-wrp {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -100;

    @include bigMobile {
      position: static;

      & > img {
        height: 150px;
      }
    }
  }
}
</style>
