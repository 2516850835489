<template>
  <div v-if="_T('@Attention GV') !== 'false'" class="header-banner">
    <div class="header-banner__wrp">
      <NuxtLink
        v-if="_T('@Attention GV link') !== 'false'"
        class="header-banner__title"
        :to="`/${_T('@Attention GV link')}`"
      >
        {{ _T("@Attention GV") }}
      </NuxtLink>

      <p v-else class="header-banner__title">
        {{ _T("@Attention GV") }}
      </p>
    </div>
  </div>
</template>

<script setup>
const backgroundColor = _T("@Attention GV color");
</script>

<style lang="scss" scoped>
.header-banner {
  background-color: v-bind(backgroundColor);
}
</style>
